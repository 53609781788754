import { Union } from "./fable_modules/fable-library.3.7.5/Types.js";
import { union_type, option_type, string_type } from "./fable_modules/fable-library.3.7.5/Reflection.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.5/String.js";
import { parsePath, oneOf, stringParam, s, map } from "./fable_modules/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { singleton, collect } from "./fable_modules/fable-library.3.7.5/List.js";

export class Route extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Home"];
    }
}

export function Route$reflection() {
    return union_type("Routing.Route", [], Route, () => [[["Item", option_type(string_type)]]]);
}

export function toRouteUrl(route) {
    const topicOpt = route.fields[0];
    if (topicOpt == null) {
        return "/";
    }
    else {
        const generalTopic = topicOpt;
        return toText(printf("/?topic=%s"))(generalTopic);
    }
}

export function curry(f, x, y) {
    return f([x, y]);
}

export const Routing_route = (() => {
    let parser, queryParser;
    const parsers = singleton(map((arg0) => (new Route(0, arg0)), (parser = s(""), (queryParser = stringParam("topic"), (state) => collect(queryParser, parser(state))))));
    return (state_2) => oneOf(parsers, state_2);
})();

export function Routing_parsePath(location) {
    return parsePath(Routing_route, location);
}

