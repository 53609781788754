import { Union } from "../Client/fable_modules/fable-library.3.7.5/Types.js";
import { union_type } from "../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { replace } from "../Client/fable_modules/fable-library.3.7.5/String.js";

export class IssueType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Question", "Bug", "Request"];
    }
}

export function IssueType$reflection() {
    return union_type("IssueTypes.IssueType", [], IssueType, () => [[], [], []]);
}

export class Topic extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RDM", "Infrastructure", "Tools", "Workflows", "Metadata", "Documentation", "Other"];
    }
}

export function Topic$reflection() {
    return union_type("IssueTypes.Topic", [], Topic, () => [[["Item", IssueSubtopics_RDM$reflection()]], [["Item", IssueSubtopics_Infrastructure$reflection()]], [["Item", IssueSubtopics_Tools$reflection()]], [["Item", IssueSubtopics_Workflows$reflection()]], [["Item", IssueSubtopics_Metadata$reflection()]], [["Item", IssueSubtopics_Documentation$reflection()]], []]);
}

export class IssueGeneralTopic extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RDM", "Infrastructure", "Tools", "Workflows", "Metadata", "Documentation", "Other"];
    }
}

export function IssueGeneralTopic$reflection() {
    return union_type("IssueTypes.IssueGeneralTopic", [], IssueGeneralTopic, () => [[], [], [], [], [], [], []]);
}

export function IssueType_ofString_Z721C83C5(str) {
    switch (str) {
        case "Question": {
            return new IssueType(0);
        }
        case "Bug": {
            return new IssueType(1);
        }
        case "Request": {
            return new IssueType(2);
        }
        default: {
            throw (new Error(`Unable to parse '${str}' to IssueType.`));
        }
    }
}

export class IssueSubtopics_RDM extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DataSecurity", "DataLiteracy", "Teaching", "ARCStructure", "AnnotationPrinciples", "More"];
    }
}

export function IssueSubtopics_RDM$reflection() {
    return union_type("IssueTypes.IssueSubtopics.RDM", [], IssueSubtopics_RDM, () => [[], [], [], [], [], []]);
}

export class IssueSubtopics_Infrastructure extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RegistrationLogin", "DataHUB", "MetadataRegistry", "Invenio", "InfrastructureCode", "More"];
    }
}

export function IssueSubtopics_Infrastructure$reflection() {
    return union_type("IssueTypes.IssueSubtopics.Infrastructure", [], IssueSubtopics_Infrastructure, () => [[], [], [], [], [], []]);
}

export class IssueSubtopics_Tools extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ARCCommander", "ARCitect", "Swate", "DataPLAN", "Swobup", "Converters", "More"];
    }
}

export function IssueSubtopics_Tools$reflection() {
    return union_type("IssueTypes.IssueSubtopics.Tools", [], IssueSubtopics_Tools, () => [[], [], [], [], [], [], []]);
}

export class IssueSubtopics_Workflows extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CWL", "Galaxy", "More"];
    }
}

export function IssueSubtopics_Workflows$reflection() {
    return union_type("IssueTypes.IssueSubtopics.Workflows", [], IssueSubtopics_Workflows, () => [[], [], []]);
}

export class IssueSubtopics_Metadata extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OntologyUpdate", "SwateTemplate", "More"];
    }
}

export function IssueSubtopics_Metadata$reflection() {
    return union_type("IssueTypes.IssueSubtopics.Metadata", [], IssueSubtopics_Metadata, () => [[], [], []]);
}

export class IssueSubtopics_Documentation extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["KnowledgeBase", "More"];
    }
}

export function IssueSubtopics_Documentation$reflection() {
    return union_type("IssueTypes.IssueSubtopics.Documentation", [], IssueSubtopics_Documentation, () => [[], []]);
}

export function IssueSubtopics_RDM__get_toString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Data Literacy";
        }
        case 2: {
            return "Teaching";
        }
        case 3: {
            return "ARC Structure";
        }
        case 4: {
            return "Annotation Principles";
        }
        case 5: {
            return "More";
        }
        default: {
            return "Data Security";
        }
    }
}

export function IssueSubtopics_RDM_ofString_Z721C83C5(str) {
    switch (str) {
        case "Data Security":
        case "DataSecurity": {
            return new IssueSubtopics_RDM(0);
        }
        case "Data Literacy":
        case "DataLiteracy": {
            return new IssueSubtopics_RDM(1);
        }
        case "Teaching": {
            return new IssueSubtopics_RDM(2);
        }
        case "ARC Structure":
        case "ARCStructure": {
            return new IssueSubtopics_RDM(3);
        }
        case "Annotation Principles":
        case "AnnotationPrinciples": {
            return new IssueSubtopics_RDM(4);
        }
        case "More": {
            return new IssueSubtopics_RDM(5);
        }
        default: {
            throw (new Error(`Could not parse ${str} to RDM subtopic`));
        }
    }
}

export function IssueSubtopics_Infrastructure__get_toString(this$) {
    switch (this$.tag) {
        case 1: {
            return "DataHUB";
        }
        case 2: {
            return "Metadata Registry";
        }
        case 3: {
            return "Invenio";
        }
        case 4: {
            return "Infrastructure Code";
        }
        case 5: {
            return "More";
        }
        default: {
            return "Registration Login";
        }
    }
}

export function IssueSubtopics_Infrastructure_ofString_Z721C83C5(str) {
    switch (str) {
        case "Registration Login":
        case "RegistrationLogin": {
            return new IssueSubtopics_Infrastructure(0);
        }
        case "GitLab":
        case "DataHUB": {
            return new IssueSubtopics_Infrastructure(1);
        }
        case "Metadata Registry":
        case "MetadataRegistry": {
            return new IssueSubtopics_Infrastructure(2);
        }
        case "Invenio": {
            return new IssueSubtopics_Infrastructure(3);
        }
        case "Infrastructure Code":
        case "InfrastructureCode": {
            return new IssueSubtopics_Infrastructure(4);
        }
        case "More": {
            return new IssueSubtopics_Infrastructure(5);
        }
        default: {
            throw (new Error(`Could not parse ${str} to Infrastructure subtopic`));
        }
    }
}

export function IssueSubtopics_Tools__get_toString(this$) {
    switch (this$.tag) {
        case 1: {
            return "ARCitect";
        }
        case 2: {
            return "Swate";
        }
        case 3: {
            return "DataPLAN";
        }
        case 4: {
            return "Swobup";
        }
        case 5: {
            return "Converters";
        }
        case 6: {
            return "More";
        }
        default: {
            return "ARC Commander";
        }
    }
}

export function IssueSubtopics_Tools_ofString_Z721C83C5(str) {
    switch (str) {
        case "ARC Commander": {
            return new IssueSubtopics_Tools(0);
        }
        case "ARCitect": {
            return new IssueSubtopics_Tools(1);
        }
        case "Swate": {
            return new IssueSubtopics_Tools(2);
        }
        case "DMP Generator":
        case "DataPLAN": {
            return new IssueSubtopics_Tools(3);
        }
        case "Swobup": {
            return new IssueSubtopics_Tools(4);
        }
        case "Converters": {
            return new IssueSubtopics_Tools(5);
        }
        case "More": {
            return new IssueSubtopics_Tools(6);
        }
        default: {
            throw (new Error(`Could not parse ${str} to Tools subtopic`));
        }
    }
}

export function IssueSubtopics_Workflows__get_toString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Galaxy";
        }
        case 2: {
            return "More";
        }
        default: {
            return "CWL";
        }
    }
}

export function IssueSubtopics_Workflows_ofString_Z721C83C5(str) {
    switch (str) {
        case "CWL": {
            return new IssueSubtopics_Workflows(0);
        }
        case "Galaxy": {
            return new IssueSubtopics_Workflows(1);
        }
        case "More": {
            return new IssueSubtopics_Workflows(2);
        }
        default: {
            throw (new Error(`Could not parse ${str} to Workflows subtopic`));
        }
    }
}

export function IssueSubtopics_Metadata__get_toString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Swate Template";
        }
        case 2: {
            return "More";
        }
        default: {
            return "Ontology Update";
        }
    }
}

export function IssueSubtopics_Metadata_ofString_Z721C83C5(str) {
    switch (str) {
        case "Ontology Update":
        case "OntologyUpdate": {
            return new IssueSubtopics_Metadata(0);
        }
        case "Swate Template":
        case "SwateTemplate": {
            return new IssueSubtopics_Metadata(1);
        }
        case "More": {
            return new IssueSubtopics_Metadata(2);
        }
        default: {
            throw (new Error(`Could not parse ${str} to Metadata subtopic`));
        }
    }
}

export function IssueSubtopics_Documentation__get_toString(this$) {
    if (this$.tag === 1) {
        return "More";
    }
    else {
        return "Knowledge Base";
    }
}

export function IssueSubtopics_Documentation_ofString_Z721C83C5(str) {
    switch (str) {
        case "Knowledge Base":
        case "KnowledgeBase": {
            return new IssueSubtopics_Documentation(0);
        }
        case "More": {
            return new IssueSubtopics_Documentation(1);
        }
        default: {
            throw (new Error(`Could not parse ${str} to Metadata subtopic`));
        }
    }
}

export function Topic__get_toSubCategoryString(this$) {
    switch (this$.tag) {
        case 1: {
            return IssueSubtopics_Infrastructure__get_toString(this$.fields[0]);
        }
        case 2: {
            return IssueSubtopics_Tools__get_toString(this$.fields[0]);
        }
        case 3: {
            return IssueSubtopics_Workflows__get_toString(this$.fields[0]);
        }
        case 4: {
            return IssueSubtopics_Metadata__get_toString(this$.fields[0]);
        }
        case 5: {
            return IssueSubtopics_Documentation__get_toString(this$.fields[0]);
        }
        case 6: {
            return IssueGeneralTopic__get_toString(new IssueGeneralTopic(6));
        }
        default: {
            return IssueSubtopics_RDM__get_toString(this$.fields[0]);
        }
    }
}

export function Topic__get_toCategoryString(this$) {
    switch (this$.tag) {
        case 1: {
            return IssueGeneralTopic__get_toString(new IssueGeneralTopic(1));
        }
        case 2: {
            return IssueGeneralTopic__get_toString(new IssueGeneralTopic(2));
        }
        case 3: {
            return IssueGeneralTopic__get_toString(new IssueGeneralTopic(3));
        }
        case 4: {
            return IssueGeneralTopic__get_toString(new IssueGeneralTopic(4));
        }
        case 5: {
            return IssueGeneralTopic__get_toString(new IssueGeneralTopic(5));
        }
        case 6: {
            return IssueGeneralTopic__get_toString(new IssueGeneralTopic(6));
        }
        default: {
            return IssueGeneralTopic__get_toString(new IssueGeneralTopic(0));
        }
    }
}

export function Topic__get_toUrlString(this$) {
    const rmWhitespace = (str) => replace(str, " ", "");
    switch (this$.tag) {
        case 1: {
            return `${rmWhitespace(IssueGeneralTopic__get_toString(new IssueGeneralTopic(1)))}_${rmWhitespace(IssueSubtopics_Infrastructure__get_toString(this$.fields[0]))}`;
        }
        case 2: {
            return `${rmWhitespace(IssueGeneralTopic__get_toString(new IssueGeneralTopic(2)))}_${rmWhitespace(IssueSubtopics_Tools__get_toString(this$.fields[0]))}`;
        }
        case 3: {
            return `${rmWhitespace(IssueGeneralTopic__get_toString(new IssueGeneralTopic(3)))}_${rmWhitespace(IssueSubtopics_Workflows__get_toString(this$.fields[0]))}`;
        }
        case 4: {
            return `${rmWhitespace(IssueGeneralTopic__get_toString(new IssueGeneralTopic(4)))}_${rmWhitespace(IssueSubtopics_Metadata__get_toString(this$.fields[0]))}`;
        }
        case 5: {
            return `${rmWhitespace(IssueGeneralTopic__get_toString(new IssueGeneralTopic(5)))}_${rmWhitespace(IssueSubtopics_Documentation__get_toString(this$.fields[0]))}`;
        }
        case 6: {
            return rmWhitespace(IssueGeneralTopic__get_toString(new IssueGeneralTopic(6)));
        }
        default: {
            return `${rmWhitespace(IssueGeneralTopic__get_toString(new IssueGeneralTopic(0)))}_${rmWhitespace(IssueSubtopics_RDM__get_toString(this$.fields[0]))}`;
        }
    }
}

export function Topic_ofUrlString_Z721C83C5(str) {
    let patternInput;
    const ind = str.indexOf("_") | 0;
    patternInput = [str.slice(void 0, (ind - 1) + 1), str.slice(ind + 1, str.length)];
    const subtopic = patternInput[1];
    const matchValue = IssueGeneralTopic_ofString_Z721C83C5(patternInput[0]);
    switch (matchValue.tag) {
        case 1: {
            return new Topic(1, IssueSubtopics_Infrastructure_ofString_Z721C83C5(subtopic));
        }
        case 2: {
            return new Topic(2, IssueSubtopics_Tools_ofString_Z721C83C5(subtopic));
        }
        case 3: {
            return new Topic(3, IssueSubtopics_Workflows_ofString_Z721C83C5(subtopic));
        }
        case 4: {
            return new Topic(4, IssueSubtopics_Metadata_ofString_Z721C83C5(subtopic));
        }
        case 5: {
            return new Topic(5, IssueSubtopics_Documentation_ofString_Z721C83C5(subtopic));
        }
        case 6: {
            return new Topic(6);
        }
        default: {
            return new Topic(0, IssueSubtopics_RDM_ofString_Z721C83C5(subtopic));
        }
    }
}

export function IssueGeneralTopic_ofString_Z721C83C5(str) {
    switch (str) {
        case "RDM":
        case "Research Data Management":
        case "ResearchDataManagement": {
            return new IssueGeneralTopic(0);
        }
        case "Infrastructure": {
            return new IssueGeneralTopic(1);
        }
        case "Tools": {
            return new IssueGeneralTopic(2);
        }
        case "Workflows": {
            return new IssueGeneralTopic(3);
        }
        case "Metadata": {
            return new IssueGeneralTopic(4);
        }
        case "Documentation": {
            return new IssueGeneralTopic(5);
        }
        case "Other": {
            return new IssueGeneralTopic(6);
        }
        default: {
            throw (new Error(`Unable to parse '${str}' to IssueCategory.`));
        }
    }
}

export function IssueGeneralTopic__get_toString(this$) {
    switch (this$.tag) {
        case 1: {
            return "Infrastructure";
        }
        case 2: {
            return "Tools";
        }
        case 3: {
            return "Workflows";
        }
        case 4: {
            return "Metadata";
        }
        case 5: {
            return "Documentation";
        }
        case 6: {
            return "Other";
        }
        default: {
            return "Research Data Management";
        }
    }
}

export function IssueGeneralTopic__get_subcategories(this$) {
    switch (this$.tag) {
        case 1: {
            return [new Topic(1, new IssueSubtopics_Infrastructure(1)), new Topic(1, new IssueSubtopics_Infrastructure(4)), new Topic(1, new IssueSubtopics_Infrastructure(3)), new Topic(1, new IssueSubtopics_Infrastructure(2)), new Topic(1, new IssueSubtopics_Infrastructure(0)), new Topic(1, new IssueSubtopics_Infrastructure(5))];
        }
        case 2: {
            return [new Topic(2, new IssueSubtopics_Tools(0)), new Topic(2, new IssueSubtopics_Tools(5)), new Topic(2, new IssueSubtopics_Tools(3)), new Topic(2, new IssueSubtopics_Tools(2)), new Topic(2, new IssueSubtopics_Tools(4)), new Topic(2, new IssueSubtopics_Tools(6))];
        }
        case 3: {
            return [new Topic(3, new IssueSubtopics_Workflows(0)), new Topic(3, new IssueSubtopics_Workflows(1)), new Topic(3, new IssueSubtopics_Workflows(2))];
        }
        case 4: {
            return [new Topic(4, new IssueSubtopics_Metadata(1)), new Topic(4, new IssueSubtopics_Metadata(0)), new Topic(4, new IssueSubtopics_Metadata(2))];
        }
        case 5: {
            return [new Topic(5, new IssueSubtopics_Documentation(0)), new Topic(5, new IssueSubtopics_Documentation(1))];
        }
        case 6: {
            return [];
        }
        default: {
            return [new Topic(0, new IssueSubtopics_RDM(4)), new Topic(0, new IssueSubtopics_RDM(3)), new Topic(0, new IssueSubtopics_RDM(1)), new Topic(0, new IssueSubtopics_RDM(0)), new Topic(0, new IssueSubtopics_RDM(2)), new Topic(0, new IssueSubtopics_RDM(5))];
        }
    }
}

