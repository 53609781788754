import { empty, singleton, append, delay, toList } from "./fable_modules/fable-library.3.7.5/Seq.js";
import { createElement } from "react";
import { createObj } from "./fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.19.0/./ElementBuilders.fs.js";
import { singleton as singleton_1, ofArray, empty as empty_1 } from "./fable_modules/fable-library.3.7.5/List.js";
import { join } from "./fable_modules/fable-library.3.7.5/String.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { Msg } from "./State.fs.js";

export function loadingModal(model, dispatch) {
    const props_6 = toList(delay(() => append(singleton(["id", "modal-sample"]), delay(() => append(model.LoadingModal ? singleton(["className", "is-active"]) : empty(), delay(() => {
        let elems_1, elems;
        return singleton((elems_1 = [createElement("div", createObj(Helpers_combineClasses("modal-background", empty_1()))), createElement("span", createObj(ofArray([["style", {
            color: "white",
            zIndex: 31,
        }], ["className", join(" ", ["icon fa-6x fa-spin"])], (elems = [createElement("i", {
            className: "fa-solid fa-circle-notch",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("button", createObj(Helpers_combineClasses("modal-close", singleton_1(["onClick", (_arg1) => {
            dispatch(new Msg(3, false));
        }]))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
    }))))));
    return createElement("div", createObj(Helpers_combineClasses("modal", props_6)));
}

