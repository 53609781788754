import { split, printf, toText } from "../Client/fable_modules/fable-library.3.7.5/String.js";
import { Record } from "../Client/fable_modules/fable-library.3.7.5/Types.js";
import { Model$reflection } from "./FormModel.fs.js";
import { ClientCaptcha$reflection } from "./CaptchaTypes.fs.js";
import { record_type, union_type, lambda_type, class_type, unit_type, tuple_type } from "../Client/fable_modules/fable-library.3.7.5/Reflection.js";
import { FSharpResult$2 } from "../Client/fable_modules/fable-library.3.7.5/Choice.js";

export function Route_builder(typeName, methodName) {
    return toText(printf("/api/%s/%s"))(typeName)(methodName);
}

export function Route_deconstructor(path) {
    const splitArr = split(path, ["/"], null, 1);
    return [splitArr[1], splitArr[2]];
}

export class IHelpdeskAPI extends Record {
    constructor(submitIssue, getCaptcha, checkCaptcha) {
        super();
        this.submitIssue = submitIssue;
        this.getCaptcha = getCaptcha;
        this.checkCaptcha = checkCaptcha;
    }
}

export function IHelpdeskAPI$reflection() {
    return record_type("Shared.IHelpdeskAPI", [], IHelpdeskAPI, () => [["submitIssue", lambda_type(tuple_type(Model$reflection(), ClientCaptcha$reflection()), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))], ["getCaptcha", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [ClientCaptcha$reflection()]))], ["checkCaptcha", lambda_type(ClientCaptcha$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [ClientCaptcha$reflection(), ClientCaptcha$reflection()], FSharpResult$2, () => [[["ResultValue", ClientCaptcha$reflection()]], [["ErrorValue", ClientCaptcha$reflection()]]])]))]]);
}

