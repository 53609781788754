import { createElement } from "react";
import { singleton, ofArray } from "../fable_modules/fable-library.3.7.5/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { createObj } from "../fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.19.0/./ElementBuilders.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.8.0.1/Fable.React.Extensions.fs.js";
import { Model } from "../../Shared/FormModel.fs.js";
import { Msg } from "../State.fs.js";

export function issueContentElement(model, dispatch) {
    let elms_1, elms;
    const elms_2 = singleton((elms_1 = ofArray([(elms = ofArray([createElement("span", {
        children: ["Description "],
    }), createElement("span", {
        style: {
            color: "#c21f3a",
        },
        children: "*",
    })]), createElement("label", {
        className: "label",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("textarea", createObj(Helpers_combineClasses("textarea", ofArray([["id", "DescriptionInput"], ["placeholder", "please describe your issue .."], ["onChange", (e) => {
        let inputRecord;
        dispatch(new Msg(7, (inputRecord = model.FormModel, new Model(inputRecord.IssueType, inputRecord.IssueTopic, inputRecord.IssueTitle, Browser_Types_Event__Event_get_Value(e), inputRecord.UserEmail))));
    }]]))))]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })));
    return createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

