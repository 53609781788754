import { Record } from "../Client/fable_modules/fable-library.3.7.5/Types.js";
import { IssueType, Topic$reflection, IssueType$reflection } from "./IssueTypes.fs.js";
import { record_type, string_type, option_type } from "../Client/fable_modules/fable-library.3.7.5/Reflection.js";

export class Model extends Record {
    constructor(IssueType, IssueTopic, IssueTitle, IssueContent, UserEmail) {
        super();
        this.IssueType = IssueType;
        this.IssueTopic = IssueTopic;
        this.IssueTitle = IssueTitle;
        this.IssueContent = IssueContent;
        this.UserEmail = UserEmail;
    }
}

export function Model$reflection() {
    return record_type("Form.Model", [], Model, () => [["IssueType", IssueType$reflection()], ["IssueTopic", option_type(Topic$reflection())], ["IssueTitle", string_type], ["IssueContent", string_type], ["UserEmail", string_type]]);
}

export function Model_init() {
    return new Model(new IssueType(0), void 0, "", "", "");
}

