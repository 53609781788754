import { createElement } from "react";
import { cons, ofArray, singleton } from "../fable_modules/fable-library.3.7.5/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { createObj } from "../fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.19.0/./ElementBuilders.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.8.0.1/Fable.React.Extensions.fs.js";
import { Model } from "../../Shared/FormModel.fs.js";
import { Msg } from "../State.fs.js";
import { join } from "../fable_modules/fable-library.3.7.5/String.js";

export function userEmailElement(model, dispatch) {
    let elms_1, elms, props_8, elems_2, elems_1;
    const elms_2 = singleton((elms_1 = ofArray([(elms = singleton(createElement("p", {
        children: ["Email"],
    })), createElement("label", {
        className: "label",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), createElement("p", createObj(Helpers_combineClasses("help", singleton(["children", "If you want updates about your issue you can give us your email address. We will keep your contact information only as long as your issue is open."])))), (props_8 = ofArray([["className", "has-icons-left"], (elems_2 = [createElement("input", createObj(cons(["type", "email"], Helpers_combineClasses("input", ofArray([["id", "EmailInput"], ["placeholder", "Email"], ["onChange", (e) => {
        let inputRecord;
        dispatch(new Msg(7, (inputRecord = model.FormModel, new Model(inputRecord.IssueType, inputRecord.IssueTopic, inputRecord.IssueTitle, inputRecord.IssueContent, Browser_Types_Event__Event_get_Value(e)))));
    }]]))))), createElement("span", createObj(ofArray([["className", join(" ", ["icon is-small is-left"])], (elems_1 = [createElement("i", {
        className: "fas fa-envelope",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("div", createObj(Helpers_combineClasses("control", props_8))))]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })));
    return createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

