import { ofArray } from "./fable_modules/fable-library.3.7.5/List.js";
import { Union, Record } from "./fable_modules/fable-library.3.7.5/Types.js";
import { union_type, class_type, list_type, lambda_type, unit_type, anonRecord_type, string_type, record_type, option_type, bool_type } from "./fable_modules/fable-library.3.7.5/Reflection.js";
import { Topic$reflection, IssueGeneralTopic$reflection } from "../Shared/IssueTypes.fs.js";
import { Model$reflection as Model$reflection_1 } from "../Shared/FormModel.fs.js";
import { ClientCaptcha$reflection } from "../Shared/CaptchaTypes.fs.js";
import { ProxyRequestException__get_ResponseText, ProxyRequestException } from "./fable_modules/Fable.Remoting.Client.7.24.0/Types.fs.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/./SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/./Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/./TypeInfo.Converter.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.5/Choice.js";
import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.24.0/./Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "./fable_modules/Fable.Remoting.Client.7.24.0/Remoting.fs.js";
import { IHelpdeskAPI$reflection, Route_builder } from "../Shared/Shared.fs.js";

export const InputIds_inputIdList = ofArray(["CaptchaInput", "TitleInput", "DescriptionInput", "EmailInput"]);

export class Model extends Record {
    constructor(DropdownIsActive, LoadingModal, DropdownActiveTopic, DropdownActiveSubtopic, FormModel, Captcha, CaptchaLoading, CaptchaDoneWrong) {
        super();
        this.DropdownIsActive = DropdownIsActive;
        this.LoadingModal = LoadingModal;
        this.DropdownActiveTopic = DropdownActiveTopic;
        this.DropdownActiveSubtopic = DropdownActiveSubtopic;
        this.FormModel = FormModel;
        this.Captcha = Captcha;
        this.CaptchaLoading = CaptchaLoading;
        this.CaptchaDoneWrong = CaptchaDoneWrong;
    }
}

export function Model$reflection() {
    return record_type("State.Model", [], Model, () => [["DropdownIsActive", bool_type], ["LoadingModal", bool_type], ["DropdownActiveTopic", option_type(IssueGeneralTopic$reflection())], ["DropdownActiveSubtopic", option_type(Topic$reflection())], ["FormModel", Model$reflection_1()], ["Captcha", option_type(ClientCaptcha$reflection())], ["CaptchaLoading", bool_type], ["CaptchaDoneWrong", bool_type]]);
}

export function System_Exception__Exception_GetPropagatedError(this$) {
    let matchValue;
    if (this$ instanceof ProxyRequestException) {
        return ((matchValue = SimpleJson_tryParse(ProxyRequestException__get_ResponseText(this$)), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(anonRecord_type(["error", string_type], ["handled", bool_type], ["ignored", bool_type]))) : (() => {
            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
        })())).error;
    }
    else {
        return this$.message;
    }
}

export function curry(f, a, b) {
    return f([a, b]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ToggleIssueCategoryDropdown", "UpdateDropdownActiveTopic", "UpdateDropdownActiveSubtopic", "UpdateLoadingModal", "UpdateCaptchaLoading", "UpdateCaptchaDoneWrong", "UpdateCaptchaClient", "UpdateFormModel", "SubmitIssueRequest", "SubmitIssueResponse", "GenericError", "GetCaptcha", "GetCaptchaResponse", "CheckCaptcha", "CheckCaptchaResponse"];
    }
}

export function Msg$reflection() {
    return union_type("State.Msg", [], Msg, () => [[], [["Item", option_type(IssueGeneralTopic$reflection())]], [["Item", option_type(Topic$reflection())]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", option_type(ClientCaptcha$reflection())]], [["Item", Model$reflection_1()]], [], [], [["Item1", list_type(lambda_type(lambda_type(Msg$reflection(), unit_type), unit_type))], ["Item2", class_type("System.Exception")]], [], [["Item", option_type(ClientCaptcha$reflection())]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [ClientCaptcha$reflection(), ClientCaptcha$reflection()], FSharpResult$2, () => [[["ResultValue", ClientCaptcha$reflection()]], [["ErrorValue", ClientCaptcha$reflection()]]])]]]);
}

export const api = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), IHelpdeskAPI$reflection());

