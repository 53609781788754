import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.5/Seq.js";
import { createElement } from "react";
import { cons, ofArray, singleton as singleton_1 } from "../fable_modules/fable-library.3.7.5/List.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { value as value_85 } from "../fable_modules/fable-library.3.7.5/Option.js";
import { join } from "../fable_modules/fable-library.3.7.5/String.js";
import { createObj } from "../fable_modules/fable-library.3.7.5/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.19.0/./ElementBuilders.fs.js";
import { Msg } from "../State.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.8.0.1/Fable.React.Extensions.fs.js";
import { ClientCaptcha } from "../../Shared/CaptchaTypes.fs.js";

export function mainElement(model, dispatch) {
    const elms_4 = toList(delay(() => {
        let elms;
        return append(singleton((elms = singleton_1(createElement("p", {
            children: ["Captcha"],
        })), createElement("label", {
            className: "label",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))), delay(() => {
            let src, props_3, elems_1, properties_2;
            return append((model.Captcha != null) ? ((src = ("data:image/png;base64, " + value_85(model.Captcha).ImageBase64), singleton((props_3 = ofArray([["className", join(" ", ["has-ratio"])], ["style", {
                textAlign: "center",
            }], (elems_1 = [createElement("img", createObj(ofArray([(properties_2 = ofArray([["width", 100 + "%"], ["maxWidth", 400 + "px"]]), ["style", createObj(properties_2)]), ["alt", "Captcha"], ["src", src]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("div", createObj(Helpers_combineClasses("field", props_3))))))) : empty(), delay(() => {
                let props_23, elems_8, elms_1, props_7, elems_2, children_1, props_12, properties_8, elems_4, elms_2;
                return append(singleton((props_23 = ofArray([["className", "has-addons"], (elems_8 = [(elms_1 = singleton_1((props_7 = ofArray([["className", "is-small"], ["className", join(" ", ["is-nfdidark"])], ["title", "Get new Captcha"], ["onClick", (_arg1) => {
                    dispatch(new Msg(11));
                }], (elems_2 = [(children_1 = singleton_1(createElement("i", {
                    className: join(" ", toList(delay(() => append(singleton("fa-solid fa-repeat"), delay(() => (model.CaptchaLoading ? singleton("fa-spin") : empty())))))),
                })), createElement("span", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_1)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("a", createObj(Helpers_combineClasses("button", props_7))))), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                })), (props_12 = ofArray([(properties_8 = singleton_1(["width", 100 + "%"]), ["style", createObj(properties_8)]), (elems_4 = [createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["id", "CaptchaInput"], ["className", "is-small"], ["placeholder", "Captcha"], ["onChange", (e) => {
                    let inputRecord;
                    dispatch(new Msg(6, (inputRecord = value_85(model.Captcha), new ClientCaptcha(inputRecord.Id, inputRecord.ImageBase64, Browser_Types_Event__Event_get_Value(e), inputRecord.AccessToken))));
                }]])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]), createElement("div", createObj(Helpers_combineClasses("control", props_12)))), (elms_2 = toList(delay(() => {
                    let props_16, elems_5, children_4, props_20, elems_6, children_6;
                    return ((model.Captcha != null) && (value_85(model.Captcha).AccessToken !== "")) ? singleton((props_16 = ofArray([["className", "is-small"], ["className", "is-success"], ["title", "Captcha correct!"], ["style", {
                        cursor: "default",
                        pointerEvents: "none",
                        ["--fa-animation-duration"]: "1s",
                        ["--fa-animation-iteration-count"]: "2",
                    }], ["onClick", (e_1) => {
                        e_1.preventDefault();
                        e_1.stopPropagation();
                    }], (elems_5 = [(children_4 = singleton_1(createElement("i", {
                        className: join(" ", ["fa-solid fa-check fa-bounce"]),
                    })), createElement("span", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]), createElement("a", createObj(Helpers_combineClasses("button", props_16))))) : singleton((props_20 = ofArray([["className", "is-small"], ["className", join(" ", ["is-nfdidark"])], ["title", "Check Captcha"], ["onClick", (_arg2) => {
                        dispatch(new Msg(13));
                    }], (elems_6 = [(children_6 = singleton_1(createElement("i", {
                        className: join(" ", ["fa-solid fa-share"]),
                    })), createElement("span", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
                    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]), createElement("button", createObj(Helpers_combineClasses("button", props_20)))));
                })), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))])]), createElement("div", createObj(Helpers_combineClasses("field", props_23))))), delay(() => {
                    let elms_3, props_28, elems_9;
                    return model.CaptchaDoneWrong ? singleton((elms_3 = singleton_1((props_28 = ofArray([["className", "is-danger"], (elems_9 = [createElement("button", createObj(Helpers_combineClasses("delete", singleton_1(["onClick", (_arg3) => {
                        dispatch(new Msg(5, false));
                    }])))), createElement("p", {
                        children: ["Ups! That was wrong, try again!"],
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_9))])]), createElement("div", createObj(Helpers_combineClasses("notification", props_28))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                    }))) : empty();
                }));
            }));
        }));
    }));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    });
}

