import { singleton, ofArray } from "../fable_modules/fable-library.3.7.5/List.js";
import { createObj } from "../fable_modules/fable-library.3.7.5/Util.js";
import { join } from "../fable_modules/fable-library.3.7.5/String.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.19.0/./ElementBuilders.fs.js";
import { mainElement as mainElement_1 } from "./HelpdeskCaptcha.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.5/Seq.js";
import { value as value_38 } from "../fable_modules/fable-library.3.7.5/Option.js";
import { Msg } from "../State.fs.js";
import { issueTypeElement } from "./HelpdeskCheckradio.fs.js";
import { issueTitleElement } from "./HelpdeskTitle.fs.js";
import { issueContentElement } from "./HelpdeskDescription.fs.js";
import { userEmailElement } from "./HelpdeskEmail.fs.js";

export const header = (() => {
    let properties, elems_7, props_10, elems_5, props_8, elems_4, elms, props_1, elems, props_6, elems_3, elms_1, elms_2;
    const props_14 = ofArray([(properties = ofArray([["backgroundColor", "#ECEBEB"], ["padding", ((1 + "rem") + " ") + (2 + "rem")]]), ["style", createObj(properties)]), (elems_7 = [(props_10 = ofArray([["className", join(" ", ["decrease-margin-bottom"])], (elems_5 = [(props_8 = ofArray([["className", join(" ", ["min-flex-column"])], (elems_4 = [(elms = singleton((props_1 = ofArray([["className", "is-128x128"], ["style", {
        marginLeft: 0,
        marginRight: 0,
    }], (elems = [createElement("img", {
        src: "https://raw.githubusercontent.com/nfdi4plants/Branding/master/logos/DataPLANT/DataPLANT_logo_square_bg_darkblue.svg",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]), createElement("figure", createObj(Helpers_combineClasses("image", props_1))))), createElement("div", {
        className: "media-left",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_6 = ofArray([["style", {
        margin: "auto",
    }], (elems_3 = [(elms_1 = singleton(createElement("h1", {
        style: {
            color: "grey",
        },
        children: "Submit a ticket",
    })), createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), createElement("div", createObj(Helpers_combineClasses("media-content", props_6))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]), createElement("article", createObj(Helpers_combineClasses("media", props_8))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]), createElement("div", createObj(Helpers_combineClasses("content", props_10)))), (elms_2 = singleton(createElement("h5", {
        children: "Please fill out this form to submit a new request to DataPLANT",
    })), createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])]);
    return createElement("div", createObj(Helpers_combineClasses("content", props_14)));
})();

export function captchaANDsubmit(model, dispatch) {
    let elms_2, elms, elms_1, props_4;
    const elms_3 = singleton((elms_2 = ofArray([(elms = singleton(mainElement_1(model, dispatch)), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = singleton((props_4 = toList(delay(() => {
        const isActive = ((((model.Captcha != null) && (value_38(model.Captcha).AccessToken !== "")) && (model.FormModel.IssueTitle !== "")) && (model.FormModel.IssueTopic != null)) && (model.FormModel.IssueContent !== "");
        return append(singleton_1(["onClick", (_arg1) => {
            if (isActive) {
                dispatch(new Msg(8));
            }
        }]), delay(() => append(singleton_1(["className", join(" ", ["is-nfdidark"])]), delay(() => append((!isActive) ? singleton_1(["className", "is-static"]) : empty(), delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => {
            let elems_2, elems_1;
            return singleton_1((elems_2 = [createElement("span", {
                children: ["Submit"],
            }), createElement("span", createObj(ofArray([["className", join(" ", ["icon is-small"])], (elems_1 = [createElement("i", {
                className: "fa-solid fa-share",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]));
        }))))))));
    })), createElement("button", createObj(Helpers_combineClasses("button", props_4))))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })));
    return createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    });
}

export function mainElement(model, dispatch) {
    let elems;
    const props = ofArray([["style", {}], (elems = [header, issueTypeElement(model, dispatch), issueTitleElement(model, dispatch), issueContentElement(model, dispatch), userEmailElement(model, dispatch), captchaANDsubmit(model, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props)));
}

