import { Topic__get_toSubCategoryString, Topic__get_toCategoryString, IssueGeneralTopic__get_toString, Topic, IssueSubtopics_Documentation__get_toString, IssueSubtopics_Workflows__get_toString, IssueSubtopics_Tools__get_toString, IssueSubtopics_Metadata__get_toString, IssueSubtopics_Infrastructure__get_toString, IssueSubtopics_RDM__get_toString, IssueGeneralTopic__get_subcategories, Topic__get_toUrlString, IssueGeneralTopic } from "../../Shared/IssueTypes.fs.js";
import { cons, empty as empty_1, item as item_1, singleton, ofArray } from "../fable_modules/fable-library.3.7.5/List.js";
import { Msg } from "../State.fs.js";
import { Model } from "../../Shared/FormModel.fs.js";
import { createElement } from "react";
import { safeHash, equals, createObj } from "../fable_modules/fable-library.3.7.5/Util.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.19.0/./ElementBuilders.fs.js";
import { map as map_1, length, findIndex, append, collect, empty, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.5/Seq.js";
import { value as value_40 } from "../fable_modules/fable-library.3.7.5/Option.js";
import { map, last } from "../fable_modules/fable-library.3.7.5/Array.js";
import { split, join } from "../fable_modules/fable-library.3.7.5/String.js";
import { List_except } from "../fable_modules/fable-library.3.7.5/Seq2.js";

const topicList = ofArray([new IssueGeneralTopic(0), new IssueGeneralTopic(1), new IssueGeneralTopic(4), new IssueGeneralTopic(2), new IssueGeneralTopic(3), new IssueGeneralTopic(5), new IssueGeneralTopic(6)]);

const SwitchDropdownResponsivePX = 600;

function ButtonDropdown_updateFormWithTopic(model, dispatch, topic) {
    dispatch(new Msg(0));
    let nextModel;
    const inputRecord = model.FormModel;
    nextModel = (new Model(inputRecord.IssueType, topic, inputRecord.IssueTitle, inputRecord.IssueContent, inputRecord.UserEmail));
    const pathName = `/?topic=${Topic__get_toUrlString(topic)}`;
    window.history.replaceState("", void 0, pathName);
    dispatch(new Msg(7, nextModel));
}

function ButtonDropdown_backResponsiveDropdownItem(model, dispatch) {
    let elems_1, elems, properties_3;
    const props_3 = ofArray([["onClick", (e) => {
        e.stopPropagation();
        dispatch(new Msg(1, void 0));
    }], (elems_1 = [createElement("span", createObj(ofArray([["style", {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
    }], (elems = [createElement("i", {
        style: {
            marginRight: "1rem",
        },
        className: "fa-solid fa-angle-left",
    }), createElement("span", createObj(ofArray([(properties_3 = singleton(["marginRight", 1.5 + "rem"]), ["style", createObj(properties_3)]), ["children", "Back"]])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]);
    return createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_3)));
}

export function ButtonDropdown_subcategories(model, dispatch, block) {
    let properties, elems;
    const subcategories = IssueGeneralTopic__get_subcategories(block);
    const isActive = equals(model.DropdownActiveTopic, block);
    return createElement("div", createObj(ofArray([["className", "nested-dropdown"], (properties = toList(delay(() => (isActive ? singleton_1(["display", "block"]) : empty()))), ["style", createObj(properties)]), (elems = toList(delay(() => collect((topic) => {
        let properties_1;
        let subCText;
        switch (topic.tag) {
            case 0: {
                subCText = IssueSubtopics_RDM__get_toString(topic.fields[0]);
                break;
            }
            case 1: {
                subCText = IssueSubtopics_Infrastructure__get_toString(topic.fields[0]);
                break;
            }
            case 4: {
                subCText = IssueSubtopics_Metadata__get_toString(topic.fields[0]);
                break;
            }
            case 2: {
                subCText = IssueSubtopics_Tools__get_toString(topic.fields[0]);
                break;
            }
            case 3: {
                subCText = IssueSubtopics_Workflows__get_toString(topic.fields[0]);
                break;
            }
            case 5: {
                subCText = IssueSubtopics_Documentation__get_toString(topic.fields[0]);
                break;
            }
            default: {
                throw (new Error(`Could not match ${topic} with issue subcategories.`));
            }
        }
        return singleton_1(createElement("div", createObj(ofArray([(properties_1 = toList(delay(() => (equals(model.DropdownActiveSubtopic, topic) ? append(singleton_1(["backgroundColor", "#4FB3D9"]), delay(() => singleton_1(["color", "white"]))) : empty()))), ["style", createObj(properties_1)]), ["onMouseOver", (_arg1) => {
            dispatch(new Msg(2, topic));
        }], ["onMouseOut", (_arg2) => {
            dispatch(new Msg(2, void 0));
        }], ["onClick", (e) => {
            e.stopPropagation();
            e.preventDefault();
            ButtonDropdown_updateFormWithTopic(model, dispatch, topic);
        }], ["children", subCText]]))));
    }, subcategories))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function ButtonDropdown_createDropdownItem(model, dispatch, block) {
    const props_3 = toList(delay(() => append(equals(model.DropdownActiveTopic, block) ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
        let properties;
        return append(singleton_1((properties = singleton(["paddingRight", 1 + "rem"]), ["style", createObj(properties)])), delay(() => append(singleton_1(["onMouseOver", (e) => {
            if (e.view.innerWidth > SwitchDropdownResponsivePX) {
                dispatch(new Msg(1, block));
            }
        }]), delay(() => append(singleton_1(["onMouseOut", (e_1) => {
            if (e_1.view.innerWidth > SwitchDropdownResponsivePX) {
                dispatch(new Msg(1, void 0));
            }
        }]), delay(() => append(singleton_1(["onTouchEnd", (e_2) => {
            e_2.stopPropagation();
            e_2.preventDefault();
            if ((e_2.view.innerWidth < SwitchDropdownResponsivePX) && (!equals(block, new IssueGeneralTopic(6)))) {
                dispatch(new Msg(1, block));
            }
            else if ((e_2.view.innerWidth < SwitchDropdownResponsivePX) && equals(block, new IssueGeneralTopic(6))) {
                ButtonDropdown_updateFormWithTopic(model, dispatch, new Topic(6));
            }
        }]), delay(() => append(singleton_1(["onClick", (e_3) => {
            e_3.stopPropagation();
            e_3.preventDefault();
            if (equals(block, new IssueGeneralTopic(6))) {
                ButtonDropdown_updateFormWithTopic(model, dispatch, new Topic(6));
            }
        }]), delay(() => {
            let elems_1;
            return singleton_1((elems_1 = toList(delay(() => {
                let elems;
                return append(singleton_1(createElement("span", createObj(ofArray([["style", {
                    display: "flex",
                    flexGrow: 1,
                    alignItems: "center",
                }], (elems = toList(delay(() => {
                    let properties_2;
                    return append(singleton_1(createElement("span", createObj(ofArray([(properties_2 = singleton(["marginRight", 1.5 + "rem"]), ["style", createObj(properties_2)]), ["children", IssueGeneralTopic__get_toString(block)]])))), delay(() => ((!equals(block, new IssueGeneralTopic(6))) ? singleton_1(createElement("i", {
                        style: {
                            marginLeft: "auto",
                        },
                        className: "fa-solid fa-angle-right",
                    })) : empty())));
                })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => ((!equals(block, new IssueGeneralTopic(6))) ? singleton_1(ButtonDropdown_subcategories(model, dispatch, block)) : empty())));
            })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]));
        }))))))))));
    }))));
    return createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_3)));
}

export function ButtonDropdown_createDropdownItemSubcategory(model, dispatch, topic) {
    let properties, elems;
    const props_1 = ofArray([(properties = toList(delay(() => (equals(model.DropdownActiveSubtopic, topic) ? append(singleton_1(["backgroundColor", "#4FB3D9"]), delay(() => singleton_1(["color", "white"]))) : empty()))), ["style", createObj(properties)]), ["onMouseOver", (e) => {
        if (e.view.innerWidth > SwitchDropdownResponsivePX) {
            dispatch(new Msg(2, topic));
        }
    }], ["onMouseOut", (e_1) => {
        if (e_1.view.innerWidth > SwitchDropdownResponsivePX) {
            dispatch(new Msg(2, void 0));
        }
    }], ["onClick", (e_2) => {
        e_2.stopPropagation();
        e_2.preventDefault();
        let nextModel;
        const inputRecord = model.FormModel;
        nextModel = (new Model(inputRecord.IssueType, topic, inputRecord.IssueTitle, inputRecord.IssueContent, inputRecord.UserEmail));
        const pathName = `/?topic=${Topic__get_toUrlString(topic)}`;
        window.history.replaceState("", void 0, pathName);
        dispatch(new Msg(7, nextModel));
        dispatch(new Msg(0));
    }], (elems = toList(delay(() => {
        let subCText;
        switch (topic.tag) {
            case 0: {
                subCText = IssueSubtopics_RDM__get_toString(topic.fields[0]);
                break;
            }
            case 1: {
                subCText = IssueSubtopics_Infrastructure__get_toString(topic.fields[0]);
                break;
            }
            case 4: {
                subCText = IssueSubtopics_Metadata__get_toString(topic.fields[0]);
                break;
            }
            case 2: {
                subCText = IssueSubtopics_Tools__get_toString(topic.fields[0]);
                break;
            }
            case 3: {
                subCText = IssueSubtopics_Workflows__get_toString(topic.fields[0]);
                break;
            }
            case 5: {
                subCText = IssueSubtopics_Documentation__get_toString(topic.fields[0]);
                break;
            }
            default: {
                throw (new Error(`Could not match ${topic} with issue subcategories.`));
            }
        }
        return singleton_1(createElement("span", {
            children: subCText,
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]);
    return createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_1)));
}

function ButtonDropdown_findCurrentTopicIndex(item, itemList) {
    if (item == null) {
        return -1;
    }
    else {
        return findIndex((x) => equals(x, value_40(item)), itemList) | 0;
    }
}

function ButtonDropdown_findNextTopicIndex(item, itemList) {
    const current = ButtonDropdown_findCurrentTopicIndex(item, itemList) | 0;
    if (current >= (length(itemList) - 1)) {
        return (length(itemList) - 1) | 0;
    }
    else {
        return (current + 1) | 0;
    }
}

function ButtonDropdown_findPreviousTopicIndec(item, itemList) {
    const current = ButtonDropdown_findCurrentTopicIndex(item, itemList) | 0;
    if (current <= 0) {
        return 0;
    }
    else {
        return (current - 1) | 0;
    }
}

export function ButtonDropdown_createDropdown(model, dispatch) {
    let elems_8, props_18;
    const props_20 = singleton((elems_8 = [(props_18 = toList(delay(() => append(model.DropdownIsActive ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
        let elems_7, elms, props_6, elms_1;
        return singleton_1((elems_7 = [(elms = singleton((props_6 = toList(delay(() => append(singleton_1(["tabIndex", 0]), delay(() => append(singleton_1(["title", (model.FormModel.IssueTopic == null) ? "Choose a topic" : (`${Topic__get_toCategoryString(value_40(model.FormModel.IssueTopic))} > ${Topic__get_toSubCategoryString(value_40(model.FormModel.IssueTopic))}`)]), delay(() => append(singleton_1(["onClick", (e) => {
            e.stopPropagation();
            dispatch(new Msg(0));
        }]), delay(() => append(singleton_1(["onKeyDown", (e_1) => {
            let ind, inputRecord, matchValue_1, uc, bc, inputRecord_1, ind_2;
            const matchValue = e_1.which;
            let pattern_matching_result;
            if (matchValue === 13) {
                if (!model.DropdownIsActive) {
                    pattern_matching_result = 0;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    e_1.preventDefault();
                    dispatch(new Msg(0));
                    break;
                }
                case 1: {
                    let pattern_matching_result_1;
                    if (matchValue === 9) {
                        if (model.DropdownIsActive) {
                            pattern_matching_result_1 = 0;
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                    switch (pattern_matching_result_1) {
                        case 0: {
                            e_1.preventDefault();
                            dispatch(new Msg(0));
                            break;
                        }
                        case 1: {
                            let pattern_matching_result_2;
                            if (matchValue === 37) {
                                if (model.DropdownActiveSubtopic != null) {
                                    pattern_matching_result_2 = 0;
                                }
                                else {
                                    pattern_matching_result_2 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_2 = 1;
                            }
                            switch (pattern_matching_result_2) {
                                case 0: {
                                    e_1.preventDefault();
                                    dispatch(new Msg(2, void 0));
                                    break;
                                }
                                case 1: {
                                    let pattern_matching_result_3;
                                    if (matchValue === 38) {
                                        if (model.DropdownActiveSubtopic != null) {
                                            pattern_matching_result_3 = 0;
                                        }
                                        else {
                                            pattern_matching_result_3 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_3 = 1;
                                    }
                                    switch (pattern_matching_result_3) {
                                        case 0: {
                                            e_1.preventDefault();
                                            const list = IssueGeneralTopic__get_subcategories(value_40(model.DropdownActiveTopic));
                                            dispatch(new Msg(2, (ind = (ButtonDropdown_findPreviousTopicIndec(model.DropdownActiveSubtopic, list) | 0), list[ind])));
                                            break;
                                        }
                                        case 1: {
                                            let pattern_matching_result_4;
                                            if (matchValue === 38) {
                                                pattern_matching_result_4 = 0;
                                            }
                                            else if (matchValue === 39) {
                                                if (equals(model.DropdownActiveTopic, new IssueGeneralTopic(6))) {
                                                    pattern_matching_result_4 = 1;
                                                }
                                                else {
                                                    pattern_matching_result_4 = 2;
                                                }
                                            }
                                            else if (matchValue === 13) {
                                                if (equals(model.DropdownActiveTopic, new IssueGeneralTopic(6))) {
                                                    pattern_matching_result_4 = 1;
                                                }
                                                else {
                                                    pattern_matching_result_4 = 2;
                                                }
                                            }
                                            else {
                                                pattern_matching_result_4 = 2;
                                            }
                                            switch (pattern_matching_result_4) {
                                                case 0: {
                                                    e_1.preventDefault();
                                                    dispatch(new Msg(1, item_1(ButtonDropdown_findPreviousTopicIndec(model.DropdownActiveTopic, topicList), topicList)));
                                                    break;
                                                }
                                                case 1: {
                                                    e_1.preventDefault();
                                                    const pathName = `/?topic=${Topic__get_toUrlString(new Topic(6))}`;
                                                    window.history.replaceState("", void 0, pathName);
                                                    dispatch(new Msg(0));
                                                    dispatch(new Msg(7, (inputRecord = model.FormModel, new Model(inputRecord.IssueType, new Topic(6), inputRecord.IssueTitle, inputRecord.IssueContent, inputRecord.UserEmail))));
                                                    break;
                                                }
                                                case 2: {
                                                    let pattern_matching_result_5;
                                                    if (matchValue === 39) {
                                                        if (equals(model.DropdownActiveSubtopic, void 0)) {
                                                            pattern_matching_result_5 = 0;
                                                        }
                                                        else {
                                                            pattern_matching_result_5 = 1;
                                                        }
                                                    }
                                                    else if (matchValue === 13) {
                                                        if (equals(model.DropdownActiveSubtopic, void 0)) {
                                                            pattern_matching_result_5 = 0;
                                                        }
                                                        else {
                                                            pattern_matching_result_5 = 1;
                                                        }
                                                    }
                                                    else {
                                                        pattern_matching_result_5 = 1;
                                                    }
                                                    switch (pattern_matching_result_5) {
                                                        case 0: {
                                                            e_1.preventDefault();
                                                            dispatch(new Msg(2, (matchValue_1 = value_40(model.DropdownActiveTopic), ((uc = matchValue_1, (equals(uc, new IssueGeneralTopic(0)) ? true : equals(uc, new IssueGeneralTopic(1))) ? true : equals(uc, new IssueGeneralTopic(4)))) ? IssueGeneralTopic__get_subcategories(matchValue_1)[0] : (((bc = matchValue_1, equals(bc, new IssueGeneralTopic(2)) ? true : equals(bc, new IssueGeneralTopic(3)))) ? last(IssueGeneralTopic__get_subcategories(matchValue_1)) : (() => {
                                                                throw (new Error(`Could not parse ${matchValue_1} to keyboard navigation event`));
                                                            })()))));
                                                            break;
                                                        }
                                                        case 1: {
                                                            let pattern_matching_result_6;
                                                            if (matchValue === 39) {
                                                                if (model.DropdownActiveSubtopic != null) {
                                                                    pattern_matching_result_6 = 0;
                                                                }
                                                                else {
                                                                    pattern_matching_result_6 = 1;
                                                                }
                                                            }
                                                            else if (matchValue === 13) {
                                                                if (model.DropdownActiveSubtopic != null) {
                                                                    pattern_matching_result_6 = 0;
                                                                }
                                                                else {
                                                                    pattern_matching_result_6 = 1;
                                                                }
                                                            }
                                                            else {
                                                                pattern_matching_result_6 = 1;
                                                            }
                                                            switch (pattern_matching_result_6) {
                                                                case 0: {
                                                                    e_1.preventDefault();
                                                                    const pathName_1 = `/?topic=${Topic__get_toUrlString(value_40(model.DropdownActiveSubtopic))}`;
                                                                    window.history.replaceState("", void 0, pathName_1);
                                                                    dispatch(new Msg(0));
                                                                    dispatch(new Msg(7, (inputRecord_1 = model.FormModel, new Model(inputRecord_1.IssueType, value_40(model.DropdownActiveSubtopic), inputRecord_1.IssueTitle, inputRecord_1.IssueContent, inputRecord_1.UserEmail))));
                                                                    break;
                                                                }
                                                                case 1: {
                                                                    let pattern_matching_result_7;
                                                                    if (matchValue === 40) {
                                                                        if (model.DropdownActiveSubtopic != null) {
                                                                            pattern_matching_result_7 = 0;
                                                                        }
                                                                        else {
                                                                            pattern_matching_result_7 = 1;
                                                                        }
                                                                    }
                                                                    else {
                                                                        pattern_matching_result_7 = 1;
                                                                    }
                                                                    switch (pattern_matching_result_7) {
                                                                        case 0: {
                                                                            e_1.preventDefault();
                                                                            const list_1 = IssueGeneralTopic__get_subcategories(value_40(model.DropdownActiveTopic));
                                                                            dispatch(new Msg(2, (ind_2 = (ButtonDropdown_findNextTopicIndex(model.DropdownActiveSubtopic, list_1) | 0), list_1[ind_2])));
                                                                            break;
                                                                        }
                                                                        case 1: {
                                                                            if (matchValue === 40) {
                                                                                e_1.preventDefault();
                                                                                dispatch(new Msg(1, item_1(ButtonDropdown_findNextTopicIndex(model.DropdownActiveTopic, topicList), topicList)));
                                                                            }
                                                                            break;
                                                                        }
                                                                    }
                                                                    break;
                                                                }
                                                            }
                                                            break;
                                                        }
                                                    }
                                                    break;
                                                }
                                            }
                                            break;
                                        }
                                    }
                                    break;
                                }
                            }
                            break;
                        }
                    }
                    break;
                }
            }
        }]), delay(() => {
            let elems_2, elems, properties, elems_1, properties_4;
            return singleton_1((elems_2 = [createElement("span", createObj(ofArray([["className", join(" ", ["min-hide"])], (elems = [createElement("span", createObj(ofArray([(properties = singleton(["marginRight", 5 + "px"]), ["style", createObj(properties)]), ["children", (model.FormModel.IssueTopic == null) ? "Please select" : Topic__get_toSubCategoryString(value_40(model.FormModel.IssueTopic))]]))), createElement("i", {
                className: "fa-solid fa-angle-down",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("span", createObj(ofArray([["className", join(" ", ["min-hide-opposite"])], (elems_1 = [createElement("span", createObj(ofArray([(properties_4 = singleton(["marginRight", 5 + "px"]), ["style", createObj(properties_4)]), ["children", (model.FormModel.IssueTopic == null) ? "Select" : join("", map((x) => x[0], split(Topic__get_toSubCategoryString(value_40(model.FormModel.IssueTopic)), [" "], null, 1)))]]))), createElement("i", {
                className: "fa-solid fa-angle-down",
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]));
        })))))))))), createElement("a", createObj(Helpers_combineClasses("button", props_6))))), createElement("div", {
            className: "dropdown-trigger",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = toList(delay(() => {
            let subcategories, props_11, elems_4;
            return append((model.DropdownActiveTopic != null) ? ((subcategories = IssueGeneralTopic__get_subcategories(value_40(model.DropdownActiveTopic)), singleton_1((props_11 = ofArray([["className", join(" ", ["responsive-dropdown-subcontent"])], (elems_4 = toList(delay(() => append(singleton_1(ButtonDropdown_backResponsiveDropdownItem(model, dispatch)), delay(() => append(singleton_1(createElement("hr", createObj(Helpers_combineClasses("dropdown-divider", empty_1())))), delay(() => map_1((topic) => ButtonDropdown_createDropdownItemSubcategory(model, dispatch, topic), subcategories))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]), createElement("div", createObj(Helpers_combineClasses("dropdown-content", props_11))))))) : empty(), delay(() => {
                let props_15;
                return singleton_1((props_15 = toList(delay(() => append((model.DropdownActiveTopic != null) ? singleton_1(["className", join(" ", ["responsive-dropdown-content"])]) : empty(), delay(() => {
                    let elems_5;
                    return singleton_1((elems_5 = toList(delay(() => append(map_1((topic_1) => ButtonDropdown_createDropdownItem(model, dispatch, topic_1), List_except([new IssueGeneralTopic(6)], topicList, {
                        Equals: equals,
                        GetHashCode: safeHash,
                    })), delay(() => append(singleton_1(createElement("hr", createObj(Helpers_combineClasses("dropdown-divider", empty_1())))), delay(() => singleton_1(ButtonDropdown_createDropdownItem(model, dispatch, new IssueGeneralTopic(6))))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))]));
                })))), createElement("div", createObj(Helpers_combineClasses("dropdown-content", props_15)))));
            }));
        })), createElement("div", {
            className: "dropdown-menu",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))]));
    })))), createElement("div", createObj(Helpers_combineClasses("dropdown", props_18))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_8))]));
    return createElement("div", createObj(Helpers_combineClasses("control", props_20)));
}

export function issueTitleElement(model, dispatch) {
    let elms_1, elms, props_7, elems_2, props_5, elems_1;
    const elms_2 = singleton((elms_1 = ofArray([(elms = ofArray([createElement("span", {
        children: ["Title "],
    }), createElement("span", {
        style: {
            color: "#c21f3a",
        },
        children: "*",
    })]), createElement("label", {
        className: "label",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_7 = ofArray([["className", "has-addons"], (elems_2 = [ButtonDropdown_createDropdown(model, dispatch), (props_5 = ofArray([["className", "is-expanded"], (elems_1 = [createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["id", "TitleInput"], ["placeholder", "please give your issue a title .."], ["onChange", (ev) => {
        let inputRecord;
        dispatch(new Msg(7, (inputRecord = model.FormModel, new Model(inputRecord.IssueType, inputRecord.IssueTopic, ev.target.value, inputRecord.IssueContent, inputRecord.UserEmail))));
    }]])))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("p", createObj(Helpers_combineClasses("control", props_5))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("div", createObj(Helpers_combineClasses("field", props_7))))]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })));
    return createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

