import { Record } from "../Client/fable_modules/fable-library.3.7.5/Types.js";
import { record_type, string_type, class_type } from "../Client/fable_modules/fable-library.3.7.5/Reflection.js";

export class ClientCaptcha extends Record {
    constructor(Id, ImageBase64, UserInput, AccessToken) {
        super();
        this.Id = Id;
        this.ImageBase64 = ImageBase64;
        this.UserInput = UserInput;
        this.AccessToken = AccessToken;
    }
}

export function ClientCaptcha$reflection() {
    return record_type("CaptchaTypes.ClientCaptcha", [], ClientCaptcha, () => [["Id", class_type("System.Guid")], ["ImageBase64", string_type], ["UserInput", string_type], ["AccessToken", string_type]]);
}

