import { React_functionComponent_2F9D7239 } from "./fable_modules/Feliz.1.68.0/React.fs.js";
import { createElement } from "react";
import { ofArray } from "./fable_modules/fable-library.3.7.5/List.js";
import { Bindings_swal } from "./fable_modules/Feliz.SweetAlert.2.8.0/./Bindings.fs.js";
import { createObj } from "./fable_modules/fable-library.3.7.5/Util.js";
import { System_Exception__Exception_GetPropagatedError } from "./State.fs.js";

export const bodyComp = React_functionComponent_2F9D7239(() => createElement("div", {
    className: "modal-card-body",
    children: "Your ticket was submitted successfully",
}));

export function submitSuccessfullyAlert() {
    const options = ofArray([["icon", "success"], ["html", bodyComp()], ["focusConfirm", true], ["confirmButtonColor", "#4FB3D9"]]);
    const pr = Bindings_swal.fire(createObj(options));
    pr.then();
}

export function genericErrorAlert(exn) {
    const e = System_Exception__Exception_GetPropagatedError(exn);
    const pr = Bindings_swal.fire({
        text: e,
        icon: "error",
    });
    pr.then();
}

