import { Footer, Navbar } from "@nfdi4plants/web-components";
import * as react from "react";
import { keyValueList } from "./fable_modules/fable-library.3.7.5/MapUtil.js";



export function nfdiNavbar(props, children) {
    return react.createElement("nfdi-navbar", keyValueList(props, 1), ...children);
}

export function nfdiFooter(props, children) {
    return react.createElement("nfdi-footer", keyValueList(props, 1), ...children);
}

