import { Cmd_batch, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Topic_ofUrlString_Z721C83C5 } from "../Shared/IssueTypes.fs.js";
import { value as value_20 } from "./fable_modules/fable-library.3.7.5/Option.js";
import { Model_init, Model } from "../Shared/FormModel.fs.js";
import { curry, api, Msg, Model as Model_1 } from "./State.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { Routing_parsePath } from "./Routing.fs.js";
import { singleton, ofArray } from "./fable_modules/fable-library.3.7.5/List.js";
import { genericErrorAlert, submitSuccessfullyAlert } from "./alerts.fs.js";
import { loadingModal } from "./Modal.fs.js";
import { nfdiFooter, nfdiNavbar } from "./nfdi-webcomponents.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { join } from "./fable_modules/fable-library.3.7.5/String.js";
import { createObj } from "./fable_modules/fable-library.3.7.5/Util.js";
import { mainElement } from "./FormElements/HelpdeskMain.fs.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.19.0/./ElementBuilders.fs.js";

export function urlUpdate(route, model) {
    let inputRecord;
    if (route == null) {
        return [model, Cmd_none()];
    }
    else {
        const topicStr = route.fields[0];
        return [(topicStr == null) ? model : (new Model_1(model.DropdownIsActive, model.LoadingModal, model.DropdownActiveTopic, model.DropdownActiveSubtopic, (inputRecord = model.FormModel, new Model(inputRecord.IssueType, Topic_ofUrlString_Z721C83C5(value_20(topicStr)), inputRecord.IssueTitle, inputRecord.IssueContent, inputRecord.UserEmail)), model.Captcha, model.CaptchaLoading, model.CaptchaDoneWrong)), Cmd_none()];
    }
}

export function clearInputs() {
    const inptuById = (id) => document.getElementById(id);
    const title = inptuById("TitleInput");
    title.value = "";
    const description = inptuById("DescriptionInput");
    description.value = "";
    const email = inptuById("EmailInput");
    email.value = "";
    const captcha = inptuById("CaptchaInput");
    captcha.value = "";
}

export function init(url) {
    const model = new Model_1(false, false, void 0, void 0, Model_init(), void 0, false, false);
    const initCaptcha = Cmd_OfFunc_result(new Msg(11));
    const patternInput = urlUpdate(Routing_parsePath(document.location), model);
    return [patternInput[0], Cmd_batch(ofArray([initCaptcha, patternInput[1]]))];
}

export function update(msg, model) {
    let a, a_1, a_2;
    if (msg.tag === 1) {
        return [new Model_1(model.DropdownIsActive, model.LoadingModal, msg.fields[0], model.DropdownActiveSubtopic, model.FormModel, model.Captcha, model.CaptchaLoading, model.CaptchaDoneWrong), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new Model_1(model.DropdownIsActive, model.LoadingModal, model.DropdownActiveTopic, msg.fields[0], model.FormModel, model.Captcha, model.CaptchaLoading, model.CaptchaDoneWrong), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new Model_1(model.DropdownIsActive, msg.fields[0], model.DropdownActiveTopic, model.DropdownActiveSubtopic, model.FormModel, model.Captcha, model.CaptchaLoading, model.CaptchaDoneWrong), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new Model_1(model.DropdownIsActive, model.LoadingModal, model.DropdownActiveTopic, model.DropdownActiveSubtopic, model.FormModel, model.Captcha, msg.fields[0], model.CaptchaDoneWrong), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new Model_1(model.DropdownIsActive, model.LoadingModal, model.DropdownActiveTopic, model.DropdownActiveSubtopic, model.FormModel, model.Captcha, model.CaptchaLoading, msg.fields[0]), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new Model_1(model.DropdownIsActive, model.LoadingModal, model.DropdownActiveTopic, model.DropdownActiveSubtopic, model.FormModel, msg.fields[0], model.CaptchaLoading, model.CaptchaDoneWrong), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [new Model_1(model.DropdownIsActive, model.LoadingModal, model.DropdownActiveTopic, model.DropdownActiveSubtopic, msg.fields[0], model.Captcha, model.CaptchaLoading, model.CaptchaDoneWrong), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [new Model_1(model.DropdownIsActive, true, model.DropdownActiveTopic, model.DropdownActiveSubtopic, model.FormModel, model.Captcha, model.CaptchaLoading, model.CaptchaDoneWrong), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, api.submitIssue, [model.FormModel, value_20(model.Captcha)], () => (new Msg(9)), (a = Cmd_OfFunc_result(new Msg(3, false)), (b) => curry((tupledArg) => (new Msg(10, tupledArg[0], tupledArg[1])), a, b)))];
    }
    else if (msg.tag === 9) {
        clearInputs();
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        document.getElementById("radio1Question").focus();
        const patternInput = init(void 0);
        submitSuccessfullyAlert();
        return [patternInput[0], patternInput[1]];
    }
    else if (msg.tag === 11) {
        return [new Model_1(model.DropdownIsActive, model.LoadingModal, model.DropdownActiveTopic, model.DropdownActiveSubtopic, model.FormModel, model.Captcha, true, model.CaptchaDoneWrong), Cmd_OfAsyncWith_either((x_1) => {
            Cmd_OfAsync_start(x_1);
        }, api.getCaptcha, void 0, (arg_1) => (new Msg(12, arg_1)), (a_1 = Cmd_OfFunc_result(new Msg(4, false)), (b_1) => curry((tupledArg_1) => (new Msg(10, tupledArg_1[0], tupledArg_1[1])), a_1, b_1)))];
    }
    else if (msg.tag === 12) {
        document.getElementById("CaptchaInput").value = "";
        return [new Model_1(model.DropdownIsActive, model.LoadingModal, model.DropdownActiveTopic, model.DropdownActiveSubtopic, model.FormModel, msg.fields[0], false, model.CaptchaDoneWrong), Cmd_none()];
    }
    else if (msg.tag === 13) {
        return [new Model_1(model.DropdownIsActive, true, model.DropdownActiveTopic, model.DropdownActiveSubtopic, model.FormModel, model.Captcha, model.CaptchaLoading, false), Cmd_OfAsyncWith_either((x_2) => {
            Cmd_OfAsync_start(x_2);
        }, api.checkCaptcha, value_20(model.Captcha), (arg0_4) => (new Msg(14, arg0_4)), (a_2 = Cmd_OfFunc_result(new Msg(3, false)), (b_2) => curry((tupledArg_2) => (new Msg(10, tupledArg_2[0], tupledArg_2[1])), a_2, b_2)))];
    }
    else if (msg.tag === 14) {
        if (msg.fields[0].tag === 1) {
            return [new Model_1(model.DropdownIsActive, false, model.DropdownActiveTopic, model.DropdownActiveSubtopic, model.FormModel, msg.fields[0].fields[0], model.CaptchaLoading, true), Cmd_none()];
        }
        else {
            return [new Model_1(model.DropdownIsActive, false, model.DropdownActiveTopic, model.DropdownActiveSubtopic, model.FormModel, msg.fields[0].fields[0], model.CaptchaLoading, model.CaptchaDoneWrong), Cmd_none()];
        }
    }
    else if (msg.tag === 10) {
        genericErrorAlert(msg.fields[1]);
        return [model, msg.fields[0]];
    }
    else {
        return [new Model_1(!model.DropdownIsActive, model.LoadingModal, void 0, void 0, model.FormModel, model.Captcha, model.CaptchaLoading, model.CaptchaDoneWrong), Cmd_none()];
    }
}

export function view(model, dispatch) {
    let elems_4, elms, props_5, elems_3, props_3, properties_1, elems_2, props_1, elems_1;
    const props_7 = ofArray([["className", "is-fullheight"], ["onClick", (e) => {
        if (model.DropdownIsActive) {
            dispatch(new Msg(0));
        }
    }], (elems_4 = [loadingModal(model, dispatch), (elms = singleton(nfdiNavbar([], [])), createElement("div", {
        className: "hero-head",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_5 = ofArray([["className", join(" ", ["decrease-padding"])], (elems_3 = [(props_3 = ofArray([(properties_1 = singleton(["maxWidth", 100 + "%"]), ["style", createObj(properties_1)]), (elems_2 = [(props_1 = ofArray([["className", "is-8"], ["className", "is-offset-2"], ["className", join(" ", ["decrease-padding"])], (elems_1 = [mainElement(model, dispatch)], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("div", createObj(Helpers_combineClasses("column", props_1))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]), createElement("div", createObj(Helpers_combineClasses("container", props_3))))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]), createElement("div", createObj(Helpers_combineClasses("hero-body", props_5)))), nfdiFooter([], [])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]);
    return createElement("section", createObj(Helpers_combineClasses("hero", props_7)));
}

