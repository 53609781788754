import { singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.5/Seq.js";
import { toString } from "../fable_modules/fable-library.3.7.5/Types.js";
import { join } from "../fable_modules/fable-library.3.7.5/String.js";
import { createObj, equals } from "../fable_modules/fable-library.3.7.5/Util.js";
import { Model } from "../../Shared/FormModel.fs.js";
import { Msg } from "../State.fs.js";
import { singleton as singleton_1, cons, ofArray } from "../fable_modules/fable-library.3.7.5/List.js";
import { createElement } from "react";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.19.0/./ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.68.0/./Interop.fs.js";
import { IssueType } from "../../Shared/IssueTypes.fs.js";

function myCheckradio(model, dispatch, name, issueType) {
    const children = toList(delay(() => {
        let props;
        const text = toString(issueType);
        const id = `${name}${text}`;
        return append(singleton((props = ofArray([["id", id], ["name", name], ["className", join(" ", ["is-nfdiolive"])], ["className", "is-circle"], ["className", "is-info"], ["checked", equals(model.FormModel.IssueType, issueType)], ["onChange", (e) => {
            let inputRecord;
            dispatch(new Msg(7, (inputRecord = model.FormModel, new Model(issueType, inputRecord.IssueTopic, inputRecord.IssueTitle, inputRecord.IssueContent, inputRecord.UserEmail))));
        }]]), createElement("input", createObj(cons(["type", "radio"], Helpers_combineClasses("is-checkradio", props)))))), delay(() => singleton(createElement("label", {
            htmlFor: id,
            children: text,
        }))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function issueTypeElement(model, dispatch) {
    let elms_1, elms, props_3, elems_1;
    const elms_2 = singleton_1((elms_1 = ofArray([(elms = ofArray([createElement("span", {
        children: ["Type "],
    }), createElement("span", {
        style: {
            color: "#c21f3a",
        },
        children: "*",
    })]), createElement("label", {
        className: "label",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_3 = ofArray([["style", {
        justifyContent: "space-around",
        display: "flex",
    }], (elems_1 = [myCheckradio(model, dispatch, "radio1", new IssueType(0)), myCheckradio(model, dispatch, "radio1", new IssueType(1)), myCheckradio(model, dispatch, "radio1", new IssueType(2))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]), createElement("div", createObj(Helpers_combineClasses("field", props_3))))]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })));
    return createElement("div", {
        className: "content",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    });
}

